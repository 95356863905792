import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  // Change page title on blur
  $(window).blur(function() {
    if($('title').data('original-title') != undefined){
      document.title = $('title').data('original-title');
    }
  });

  // Change page title back on focus
  $(window).focus(function() {
    if($('title').data('original-title') != undefined){
      document.title = $('title').data('original-title');
    }
  });

  const element = document.getElementById('company-link-id');
  
  if(element){
    const company_uuid = element.getAttribute('data-company-uuid');
    consumer.subscriptions.create({ channel: "CompanyAdminChannel", company_uuid: company_uuid }, {
      connected() {
        $('.online').removeClass('hide');
        $('.offline').addClass('hide');
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        $('.online').addClass('hide');
        $('.offline').removeClass('hide');
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        $('.last-orders-content').append('<tr><td><a href="'+data['order']['link']+'">'
          +data['order']['username']+'</a></td><td><a href="'+data['order']['link']+'">'+data['order']['created_at']+'</a></td></tr>');
        
        $('#lastOrdersModal').modal();
        document.title = 'NOVO PEDIDO! '

        var audio = new Audio('/bell_2.m4a');
        if($('#change-sound').attr('data-status') == 'on'){
          audio.muted = false;
        }else{
          audio.muted = true;
        }
        var playPromise = audio.play();

        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
        }
      }
    });
  }
});

